<template>
    <div>
        <b-card>
            <label class="row justify-content-center mb-1">Choose PlayList</label>
            <div class="row justify-content-center">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" label="name" :clearable="false" input-id="genre" class="col-md-8" :options="playListOptions" placeholder="Select..." v-model="playListItem" />
            </div>
            <div class="row justify-content-center my-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit" @click="onSubmit"> Convert </b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import usePlaylistList from "./usePlayListList";
import useGenre from "../genre/useGenre";
import useFile from "../file/useFile";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddSongModal from "./AddSong.vue";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        AddSongModal,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        const { storePlaylist, updatePlaylist, showPlaylist, fetchPlaylist, convertPlaylist } = usePlaylistList();
        const playListOptions = ref("");
        const playListItem = ref("");
        fetchPlaylist().then((res) => {
            playListOptions.value = res.value;
        });
        onMounted(() => {});
        const onSubmit = async () => {
            console.log(playListItem.value);
            let data = {
                playlist_id: playListItem.value,
            };
            convertPlaylist(data).then((res) => {
                context.root.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Converted",
                        icon: "BellIcon",
                        text: "PlayList Converted to Mood Successfully👋",
                        variant: "danger",
                    },
                });
            });
        };

        return {
            onSubmit,
            storePlaylist,
            updatePlaylist,
            showPlaylist,
            fetchPlaylist,
            playListOptions,
            playListItem,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
